// Sass math
@use "sass:math";

/* Color Definitions */
/* Zixi */
$zixi-purple: #2c0150;
$zixi-red: #ed1c24;
$zixi-green: #70bf4b;
$zixi-blue: #4052a3;

/* Blues */
$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;

/* Blue Grays */
$blue-gray-50: #eceff1;
$blue-gray-100: #cfd8dc;
$blue-gray-200: #b0bec5;
$blue-gray-300: #90a4ae;
$blue-gray-400: #78909c;
$blue-gray-500: #607d8b;
$blue-gray-600: #546e7a;
$blue-gray-700: #455a64;
$blue-gray-800: #37474f;
$blue-gray-900: #263238;
$blue-gray-950: #1e282b;
$blue-gray-1000: #1b2327; //#171e22;
//
// $blue-gray-950: #222d31;
// $blue-gray-1000: #1e282b;

$red-50: #ffebee;
$green-50: #e8f5e9;
$orange-50: #fff3e0;

$red-100: #ffcdd2;
$green-100: #c8e6c9;
$orange-100: #ffe0b2;

$red-200: #ef9a9a;
$green-200: #a5d6a7;
$orange-200: #ffcc80;

$red-700: #d32f2f;
$green-700: #388e3c;
$orange-700: #f57c00;

$red-800: #c62828;
$green-800: #2e7d32;
$orange-800: #ef6c00;

$red-900: #b71c1c;
$green-900: #1b5e20;
$orange-900: #e65100;

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;

/* Status */
$status-good: #2e7d32; // Google Green 800
$status-warning: #ef6c00; // Google Orange 800
$status-error: #d32f2f; // Google Red 700
$status-none: #757575; // Google Gray 600
$status-info: #0097a7; // Google Cyan 700
$purple: #7b1fa2; // Google Purple 700

/* Bootstrap Variable Overrides */
/* Grays */
$white: #fff;
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: #000;

$color-white: #fff;
$color-black: #000;
$color-disabled: $gray-200;

$blue: $blue-700;
$indigo: #673ab7;
// $purple: #9c27b0;
$pink: #e91e63;
$red: $status-error;
$orange: $status-warning;
$yellow: #ffeb3b;
$green: $status-good;
$teal: #009688;
$cyan: $status-info;
$dark: $gray-900;

$warning: $orange;
$primary: $blue;
$secondary: $blue-gray-800;

$hover-bg-color: $gray-200;
$active-bg-color: $blue-50;

/* Grid */
$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1380px,
    xxxl: 1540px
);

/* Utility */
$spacer: 1rem; //16px
$border-radius: 0.25rem;

// revert grid-gutter back to 4.6 value
$grid-gutter-width: $spacer * 2 !default;

/* Body */
$body-bg: $white;
$body-color: $gray-900;

/* Fonts */
$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-title: "Roboto Condensed", Impact, "Franklin Gothic Bold", sans-serif;
$font-size-base: 0.875rem; //14px
$small-font-size: 90%;
$tiny-font-size: 60%;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

/* Headings */
$headings-margin-bottom: $spacer;
$headings-font-weight: $font-weight-semibold;
$biggest-font-size: $font-size-base * 2; // 28px
$h1-font-size: $font-size-base * 1.714; //24px
$h2-font-size: $font-size-base * 1.425; //20px
$h3-font-size: $font-size-base * 1.286; //18px
$h4-font-size: $font-size-base * 1.143; //16px
$h5-font-size: $font-size-base; //14px
$h6-font-size: $font-size-base * 0.9; //13px

/* Navbar */
$navbar-dark-color: $white;
$navbar-height: 48px;

/* Buttons */
$btn-disabled-opacity: 0.5;
// $btn-border-radius: 1.25rem;
$btn-border-radius: 0.25rem;
$btn-border-radius-sm: 0.75rem;
$btn-border-radius-lg: 1.5rem;
$btn-focus-width: 0;

/* Inputs */
$input-btn-focus-width: 0.1rem;
$input-color: $body-color;
$input-border-color: $gray-400;
$input-placeholder-color: $gray-400;

/* Dropdown */
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 1rem;
$dropdown-spacer: 0;

/* Table */
$table-active-bg: $active-bg-color;

/* Tooltips */
$tooltip-opacity: 1;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;

/* Leaflet */
$leaflet-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5) !important;
$leaflet-box-shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.5) !important;
$leaflet-box-shadow-xs: 0 1px 1px rgba(0, 0, 0, 0.5) !important;

/* Dark Mode */
$dark-text: $blue-gray-50;
$dark-primary: $blue-300;
$dark-secondary: $blue-gray-50;
$dark-border: $blue-gray-600;
$dark-bg: $blue-gray-900;
$dark-status-none: $blue-gray-300;
//
$dark-status-good: #81c784; // Google Green 300
$dark-status-warning: #ffb74d; // Google Orange 300
$dark-status-error: #e57373; // Google Red 300
$dark-status-info: #4dd0e1; // Google Cyan 300
$dark-purple: #ba68c8; // Google Purple 300
//
$dark-primary-bg: mix($dark-bg, $dark-primary, 85%);
$dark-status-good-bg: mix($dark-bg, $status-good, 30%);
$dark-status-warning-bg: mix($dark-bg, $status-warning, 30%);
$dark-status-error-bg: mix($dark-bg, $status-error, 30%);
$dark-status-none-bg: $blue-gray-800;
$dark-status-info-bg: mix($dark-bg, $status-info, 30%);
//
$dark-status-good-bg-table: mix($dark-bg, $status-good, 60%);
$dark-status-warning-bg-table: mix($dark-bg, $status-warning, 60%);
$dark-status-error-bg-table: mix($dark-bg, $status-error, 60%);

$box-shadow-sm: 0 math.div($spacer, 8) math.div($spacer, 4) rgb(0 0 0 / 8%) !important;
$dark-box-shadow-sm: 0 math.div($spacer, 8) math.div($spacer, 4) rgb(0 0 0 / 50%) !important;
