/*!
 * Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:host,
:root {
    --fa-style-family-classic: "Font Awesome 6 Pro";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}
@font-face {
    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(../webfonts/fa-regular-400.woff2) format("woff2"), url(../webfonts/fa-regular-400.ttf) format("truetype");
}
.fa-regular,
.far {
    font-weight: 400;
}
